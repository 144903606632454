<template>
<div v-if="loaded" class="opacity-page">
	<div class="settings-content">
		<div class="list-crud profile row-space-tbf">
			<div class="space-left"></div>
			<div class="content form-group profile">
				<div class="data-group list">
					<div class="input-group profile">
						<div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
							<img v-if="urlAvatar" :src="urlAvatar" class="img-circle"/>
							<img v-else src="../../../assets/user-circle.svg" class="img-circle"/>
							<div class="text">
								<p class="title">{{ $t('profile.profile_picture') }}</p>
								<div class="with-image"><icon-upload /><p>{{ $t('profile.upload_new_picture') }}</p></div>
							</div>
							<input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
						</div>
					</div>
				</div>
				<div class="form crud">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.first_name') }}*</label>
							<div v-if="$v.user.first_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.first_name" class="error-msg">{{ errorsBe.first_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.first_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('profile.first_name_placeholder')" class="input-text" v-model="user.first_name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.last_name') }}*</label>
							<div v-if="$v.user.last_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.last_name" class="error-msg">{{ errorsBe.last_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.last_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder=" $t('profile.name_placeholder')" class="input-text" v-model="user.last_name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.email') }}*</label>
							<div v-if="$v.user.email.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.email" class="error-msg">{{ errorsBe.email.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.email.$error || error_email}">
							<div class="icon-left"><icon-email /></div>
							<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-close class="icon-clear" /></div>
							<input type="email" :placeholder="$t('profile.email_placeholder')" class="input-text" v-model="user.email">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.phone') }}*</label>
							<div v-if="$v.user.phone.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.phone" class="error-msg">{{ errorsBe.phone.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.phone.$error}">
							<div class="icon-left"><icon-phone /></div>
							<div class="icon-right" v-if="user.phone != ''" @click.stop="user.phone = ''"><icon-close class="icon-clear" /></div>
							<input type="number" :placeholder="$t('profile.phone_placeholder')" class="input-text phone" v-model="user.phone">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.language') }}</label>
							<div v-if="errorsBe.language" class="error-msg">{{ errorsBe.language.join(" | ") }}</div>
						</div>
						<div class="input-box">
						<div class="icon-left"><icon-globe /></div>
							<multiselect
							class="select-tags-tbf language-profile"
							v-model="value_language"
							label="name" 
							track-by="name" 
							:placeholder="$t('profile.choose_language')" 
							:options="optionsLanguage" 
							:option-height="104" 
							:show-labels="false"
							:allow-empty="false"
							:searchable="false"
							@input="selectLanguage">
								<template slot="singleLabel" slot-scope="props">
									<div class="optionLanguage">
										<span class="option__title">{{ $t('languages.' + props.option.name) }}</span>
									</div>
								</template>
								<template slot="option" slot-scope="props">
									<div class="optionLanguage">
										<span class="option__title">{{ $t('languages.' + props.option.name) }}</span>
									</div>
								</template>
							</multiselect>
						</div>
					</div>
					<div v-if="!showPasswords" class="input-group w-100" style="position: relative;">
						<div class="label-header">
							<label class="label">{{ $t('profile.current_passoword') }}</label>
						</div>
						<button id="buttonChangePassword" class="btn-tbf white btn-submit current-password" @click="showPasswords = true">
							<div class="icon"><icon-password /></div>
							<span class="text">{{$t('profile.change_password')}}</span>
						</button>
					</div>
					<div v-if="showPasswords" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.new_password') }}</label>
							<div v-if="errorsBe.password" class="error-msg">{{ errorsBe.password.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-password /></div>
							<div class="icon-right" v-if="user.new_password != ''" @click.stop="user.new_password = ''"><icon-close class="icon-clear" /></div>
							<input id="password" type="password" :placeholder="$t('profile.new_password_placeholder')" class="input-text" v-model="user.new_password">
						</div>
					</div>
					<div v-if="showPasswords" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('profile.repeat_new_password') }}</label>
						</div>
						<div id="password_confirmation_error" class="input-box new" v-bind:class="{has_error: $v.user.repeat_new_password.$error}">
							<div class="icon-left"><icon-password /></div>
							<div class="icon-right" v-if="user.repeat_new_password != ''" @click.stop="user.repeat_new_password = ''"><icon-close class="icon-clear" /></div>
							<input id="password_confirmation" type="password" :placeholder="$t('profile.repeat_new_password_placeholder')" class="input-text" v-model="user.repeat_new_password">
						</div>
					</div>
					<div class="input-group w-100" style="position: relative;">
						<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
						<button id="buttonUpdate" class="btn-tbf blue btn-submit" @click="saveAction()">
							<div class="loader"></div>
							<span class="text">{{$t('general.update')}}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="software-settings row-space-tbf" v-if="$auth.user().rights.admins_instance_crud">
			<div class="space-left"></div>
			<div class="content">
				<div class="title-section">{{ $t('profile.title_settings_objectives') }}</div>
				<div class="form-settings">
					<div class="item-switch">
						<div class="name">{{ $t('profile.block_promise_time') }}</div>
						<div class="action">
							<label class="switch">
								<input type="checkbox" value="1" v-model="block_promise_time" @change="change_block_promise_time">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</div>
<loader-profile-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/User'
	import iconEmail from '../../Icons/Email'
	import iconPhone from '../../Icons/Phone'
	import iconPassword from '../../Icons/Password'
	import iconUpload from '../../Icons/Upload'
	import IconGlobe from '../../Icons/Globe.vue'
	import { required, sameAs } from 'vuelidate/lib/validators'
	import LoaderProfilePage from '../../PagesLoaders/Profile'
	const isPhone = (value) => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
	export default {
		components: {
			IconClose,
			IconPeople,
			iconEmail,
			iconPhone,
			iconPassword,
			iconUpload,
			IconGlobe,
			LoaderProfilePage
		},
		data() {
			return {
				loaded: false,
				user: {
					avatar: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
                    new_password: '',
                    repeat_new_password: ''
				},
				urlAvatar: '',
				error_message: '',
				error_email: false,
				optionsLanguage: [{name:"english", id:'en'}, {name:"romanian",  id:'ro'}],
				value_language: '',
				showPasswords: false,
				errorsBe: {},
				block_promise_time: 0
			};
		},
		async mounted(){
			this.getUserData()

			if( this.$auth.user().rights.admins_instance_crud ) {
				this.getObjectiveData();
			}

			setTimeout(() => {
				$('.opacity-page').addClass('show')
				var title = this.$t('settings-navbar.profile');
				this.$root.$emit("navbar_title", title);
			}, 0)

		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				email: {required},
				phone: {
					phoneValid: isPhone
				},
                repeat_new_password: {
					sameAsPassword: sameAs('new_password')
				}
			}
		},
		methods: {
			getUserData(){
				this.urlAvatar = this.$auth.user().avatar ? this.$auth.user().avatar : ''
				this.user.first_name = this.$auth.user().first_name ? this.$auth.user().first_name : ''
				this.user.last_name = this.$auth.user().last_name ? this.$auth.user().last_name : ''
				this.user.email = this.$auth.user().email ? this.$auth.user().email : ''
				this.user.phone = this.$auth.user().phone ? this.$auth.user().phone : ''
				this.user.language = this.$auth.user().language ? this.$auth.user().language : ''
				this.value_language = this.optionsLanguage.find(el => el.id == this.$auth.user().language)
				this.loaded = true
			},
			getObjectiveData() {
				axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$auth.user().slug}/edit`)
            	.then(({data}) => {
					this.block_promise_time = !data.data.block_promise_time;
				})
			},
			selectLanguage(value){
				this.user.language = value.id
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = new FormData();

					if(this.user.avatar) { objData.append('avatar', this.user.avatar) }
					objData.append('first_name', this.user.first_name)
					objData.append('last_name', this.user.last_name)
					objData.append('email', this.user.email)
					objData.append('phone', this.user.phone)
					objData.append('language', this.user.language)
					if(this.user.new_password != '') {
						objData.append('password', this.user.new_password)
						objData.append('password_confirmation', this.user.repeat_new_password)
					}

					this.updateUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			updateUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				// objData.append('_method', 'PATCH')

				axios.post(`edit-account`, objData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							this.$auth.fetch().then(() => {
								this.$i18n.locale = this.$auth.user().language
								this.$cookies.set('language', this.$auth.user().language, '1m')
							})
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.avatar) {
								this.error_message = error.response.data.errors.avatar[0];
							} else if(error.response.data.errors.email) {
								this.error_email = error.response.data.errors.email[0];
							}
						}, 1000)
					}, 300)})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.user.avatar = files[0]
			},
			change_block_promise_time(){
				axios.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instances/set-promise-time-block`, { block_promise_time: !this.block_promise_time }).then(() => {
					this.getObjectiveData();
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.content.form-group.profile{
		border-bottom: none;
	}
	.error-msg-settings {
		text-align-last: end;
		position: absolute;
		padding: 11px 0 5px 0;
		color: red;
		font-size: 12px;
		top: -25px;
	}
	.content.form-group.profile{
		border-bottom: none;
	}
	#buttonChangePassword{
		justify-content: start;
		padding: 0 15px;
		background: #FFF;
		.icon{
			margin-right: 15px;
			svg{
				height: 16px;
				width: auto;
				.stroke-color{
					stroke: $primary;
				}
				.fill-color{
					fill: $primary;
				}
			}	
		}
		.text{
			color:$placeholderColor
		}
		&:hover{
			border: 1px solid #DCDEE1;
		}
	}
	input.input-text.phone::-webkit-outer-spin-button,
	input.input-text.phone::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>